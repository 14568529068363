import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LanguageService } from '../language.service';
import {Location} from '@angular/common';
import { HttpClient } from '@angular/common/http';

declare var self;
@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css']
})
export class ServiceComponent implements OnInit {
  service:any;
  service_short:any;
  loaded:any = false;
  currentPage:any = 0;
  currentPhoto = 1;
  currentPhotoNext = 1;
  
  @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    this.doScroll(event);
  }

  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    this.doScroll(event);
  }

  @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
    this.doScroll(event);
  }

  @HostListener('wheel', ['$event']) onMouseWheelEdge(event: any) {
    this.doScroll(event);
  }

  constructor(private window: Window, private activeRoute: ActivatedRoute, public langServ:LanguageService, private _location:Location, private http:HttpClient) { self = this; }

  ngOnInit() {
    this.activeRoute.params.subscribe(params => {
      this.service = params.id;
      if(this.service == 'video') { this.service_short = "vid"; }
      else if(this.service == 'software') { this.service_short = "sof"; }
      else if(this.service == 'hardware') { this.service_short = "har"; }
      else if(this.service == 'design') { this.service_short = "des"; }
      else if(this.service == 'cloud') { this.service_short = "clo"; }
      else if(this.service == 'network') { this.service_short = "net"; }
      this.currentPhoto = 1;
      this.currentPhotoNext = 1;
      document.body.scrollTo(0,0);
    });

    setTimeout(function(){
      self.loaded = true;
    }, 1500);
    this.window.scrollTo(0, 0);
    // setTimeout(function(){
    //   var page_list = document.getElementsByClassName('sidepage');
    //   page_list[0].className= "page-row sidepage selected";
    //   this.currentPage = 0;
    // }, 200);

  }

  doScroll(event) {
    // if(this.window.innerWidth > 1080 && this.window.innerHeight > 769){
    //   for(let i = 0; i < this.returnPages().length; i++){
    //     if(i == 0){
    //       if(document.body.scrollTop < 981 && this.currentPage != 0){
    //         var page_list = document.getElementsByClassName('sidepage');
    //         for(var pi = 0; pi <  page_list.length; pi++) { page_list[pi].className = "page-row sidepage"; }
    //         page_list[i].className= "page-row sidepage selected";
    //         this.currentPage = 0;
    //       }
    //     } else {
    //       if(document.body.scrollTop > (i * 981) && document.body.scrollTop < ((i + 1) * 981) && this.currentPage != i) {
    //         var page_list = document.getElementsByClassName('sidepage');
    //         for(var pi = 0; pi <  page_list.length; pi++) { page_list[pi].className = "page-row sidepage"; }
    //         page_list[i].className= "page-row sidepage selected";
    //         this.currentPage = i;
    //       }
    //     }
    //   }
    // }
  }

  // scrollToPage(page) {
  //   let pageDifference = Math.abs(page - this.currentPage);
  //   var page_list = document.getElementsByClassName('sidepage');
  //   for(var i = 0; i <  page_list.length; i++) { page_list[i].className = "page-row sidepage"; }
  //   page_list[page].className= "page-row sidepage selected";
  //   var elem = document.getElementsByClassName('section')[page];
  //   var calc = page * document.body.offsetHeight;
  
  //   elem.scrollIntoView({behavior: "smooth", block: "center"});
  //   this.currentPage = page;
  // }

  // NextPage(){ if(this.currentPage < document.getElementsByClassName('section').length) { this.scrollToPage(this.currentPage + 1); } }

  goBack() { this._location.back(); }

  swipeAlert(dir) {
    alert(dir);
  }

  switchCurrentPhoto(photo) { this.currentPhoto = photo; }
  switchCurrentPhotoNext(photo) { this.currentPhotoNext = photo; }
  swipeCurrentPhoto(dir) {
    if(this.service != 'design') {
      if(dir == 'right' && this.currentPhoto < 3){
        this.currentPhoto += 1;
      } else if(dir == 'left' && this.currentPhoto > 1){
        this.currentPhoto -= 1;
      }
    } else {
      if(dir == 'right' && this.currentPhoto < 2){
        this.currentPhoto += 1;
      } else if(dir == 'left' && this.currentPhoto > 1){
        this.currentPhoto -= 1;
      }
    } 
  }
  swipeCurrentPhotoNext(dir) {
    if(dir == 'right' && this.currentPhotoNext < 2){ 
      this.currentPhotoNext += 1; 
    } else if(dir == 'left' && this.currentPhotoNext > 1){ 
      this.currentPhotoNext -= 1; 
    }
  }

  returnPages() {
    return document.getElementsByClassName('section');
  }
}
