import { Component, OnInit, HostListener, ViewChild, ElementRef, Inject } from '@angular/core';
import { LanguageService } from '../language.service';
import { HttpClient } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  currentPage:any = 0;
  currentPhoto = 1;
  currentPhotoNext = 1;
  projects:any = [];


  @ViewChild('videoPlayerAbout', {static: false}) videoplayer: ElementRef;

  @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    this.doScroll(event);
  }

  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    this.doScroll(event);
  }

  @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
    this.doScroll(event);
  }

  @HostListener('wheel', ['$event']) onMouseWheelEdge(event: any) {
    this.doScroll(event);
  }

  constructor(public langServ:LanguageService, private http:HttpClient, private title: Title, private meta: Meta) { }

  ngOnInit() {
    // this.window.scrollTo(0, 0);
    this.GetLastProjects();
    setTimeout(() => {
      this.videoplayer.nativeElement.muted = true;
      this.videoplayer.nativeElement.play();
    }, 100); 
    this.title.setTitle('За нас');
    this.meta.updateTag({ name: 'description', content: 'Ние сме компания с повече от 20 години опит в хардуерните, софтуерните разработки и изпълнението на персонализирани проекти. Запознайте се с екипа ни' });
    this.meta.updateTag({ property: 'og:image', content: 'https://axion.solutions/axion-hero.jpg' });
    this.meta.updateTag({ property: 'og:title', content: 'За нас' });
    this.meta.updateTag({ property: 'og:description', content: 'Ние сме компания с повече от 20 години опит в хардуерните, софтуерните разработки и изпълнението на персонализирани проекти. Запознайте се с екипа ни' });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:url', content: 'https://axion.solutions/about-us' });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:locale', content: 'bg' });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ name: 'Copyright', content: 'Copyright © 2020 Axion Solutions LTD. All rights reserved' });
    this.meta.updateTag({ property: 'fb:app_id', content: '160295511063956' });
    this.meta.updateTag({ property: 'og:site_name', content: 'AXION – Иновативни Бизнес Решения' });
  }


  doScroll(event) {
    // if(this.window.innerWidth > 1080 && this.window.innerHeight > 769){
    //   if(document.body.scrollTop < 981 && this.currentPage != 0){
    //     var page_list = document.getElementsByClassName('sidepage');
    //     for(var i = 0; i <  page_list.length; i++) { page_list[i].className = "page-row sidepage"; }
    //     page_list[0].className= "page-row sidepage selected";
    //     this.currentPage = 0;
    //   } else if(document.body.scrollTop > 981 && document.body.scrollTop < 1962 && this.currentPage != 1) {
    //     var page_list = document.getElementsByClassName('sidepage');
    //     for(var i = 0; i <  page_list.length; i++) { page_list[i].className = "page-row sidepage"; }
    //     page_list[1].className= "page-row sidepage selected";
    //     this.currentPage = 1;
    //   } else if(document.body.scrollTop > 1962 && document.body.scrollTop < 2943 && this.currentPage != 2) {
    //     var page_list = document.getElementsByClassName('sidepage');
    //     for(var i = 0; i <  page_list.length; i++) { page_list[i].className = "page-row sidepage"; }
    //     page_list[2].className= "page-row sidepage selected";
    //     this.currentPage = 2;
    //   } else if(document.body.scrollTop > 2943 && document.body.scrollTop < 3924 && this.currentPage != 3) {
    //     var page_list = document.getElementsByClassName('sidepage');
    //     for(var i = 0; i <  page_list.length; i++) { page_list[i].className = "page-row sidepage"; }
    //     page_list[3].className= "page-row sidepage selected";
    //     this.currentPage = 3;
    //   } else if(document.body.scrollTop > 3924 && document.body.scrollTop < 4905 && this.currentPage != 4) {
    //     var page_list = document.getElementsByClassName('sidepage');
    //     for(var i = 0; i <  page_list.length; i++) { page_list[i].className = "page-row sidepage"; }
    //     page_list[4].className= "page-row sidepage selected";
    //     this.currentPage = 4;
    //   } else if(document.body.scrollTop > 4905 && document.body.scrollTop < 5886 && this.currentPage != 5) {
    //     var page_list = document.getElementsByClassName('sidepage');
    //     for(var i = 0; i <  page_list.length; i++) { page_list[i].className = "page-row sidepage"; }
    //     page_list[5].className= "page-row sidepage selected";
    //     this.currentPage = 5;
    //   }
    // }
  }

  scrollToPage(page) {
    let pageDifference = Math.abs(page - this.currentPage);
    var page_list = document.getElementsByClassName('sidepage');
    for(var i = 0; i < page_list.length; i++) { page_list[i].className = "page-row sidepage"; }
    page_list[page].className= "page-row sidepage selected";
    var elem = document.getElementsByClassName('section')[page];
    var calc = page * document.body.offsetHeight;
  
    elem.scrollIntoView({behavior: "smooth", block: "center"});
    this.currentPage = page;
  }

  NextPage(){ if(this.currentPage < 4) { this.scrollToPage(this.currentPage + 1); } }

  switchCurrentPhoto(photo) { this.currentPhoto = photo; }
  switchCurrentPhotoNext(photo) { this.currentPhotoNext = photo; }

  swipeCurrentPhoto(dir) {
    if(dir == 'right' && this.currentPhoto < 2){
      this.currentPhoto += 1;
    } else if(dir == 'left' && this.currentPhoto > 1){
      this.currentPhoto -= 1;
    }
  }
  swipeCurrentPhotoNext(dir) {
    if(dir == 'right' && this.currentPhotoNext < 3){ 
      this.currentPhotoNext += 1; 
    } else if(dir == 'left' && this.currentPhotoNext > 1){ 
      this.currentPhotoNext -= 1; 
    }
  }

  GetLastProjects() {
    this.projects = [];
    this.http.get("https://axion.solutions/api/service.php?action=get_last_projects").subscribe(
    response => { 
      this.projects = response['projects'];
      for(let i = 0; i < this.projects.length; i++) { this.projects[i].tags = this.projects[i].tag.split(";"); }
    },
    error => {  });
  }

  returnProjectTitle(project) {
    if(this.langServ.returnLanguage() == "bg") { return project.title_bg; }
    else { return project.title_en; }
  }

  returnPadZero( num, size ){
    return ( Math.pow( 10, size ) + ~~num ).toString().substring( 1 );
  }

  returnProjectLink(project) {
    return '/project/' + project.uid;
  }

}
