  <!-- Next Segment-------------------- -->
  <div class="landing-segment fit section">
    <div class="photo-holder service"></div>
    <div class="main-holder">
        <div class="link-path mobile-hide">
            <span>{{ langServ.returnTranslate('quick_page_home') }}</span>
            <div class="line"></div>
            <span>{{ langServ.returnTranslate('quick_page_projects') }}</span>
        </div>
        <div class="back-btn-holder phone-hide" style="float: left; width: 100%; margin-top: 40px; margin-bottom: 40px;">
            <div class="btn" (click)="goBack()">
                <div class="previous-ic"></div>
                <span class="btn-txt">{{ langServ.returnTranslate('back_to_projects_btn') }}</span>
            </div>
        </div>
        <div class="info-card light2">
            <div class="info">
                <span class="side-num l">01</span>
                <span class="heading s">{{ returnProjectTitle() }}</span>
                <p class="main-txt" *ngFor="let description of returnDescriptions()">{{description}}</p>
            </div>
        </div>
        <div class="info-s-card">
            <div class="img-holder light2">
                <div class="card-photo2 pro{{project}}-seg1"></div>
            </div>
        </div>
        <div class="info-s-card margin">
            <div class="img-holder light2">
                <div class="card-photo2 pro{{project}}-seg1-2"></div>
            </div>            
        </div>

        <div class="back-btn-holder desktop-hide" style="float: left; width: 100%; margin-top: 40px; margin-bottom: 40px;">
            <div class="btn" (click)="goBack()">
                <div class="previous-ic"></div>
                <span class="btn-txt">{{ langServ.returnTranslate('back_to_projects_btn') }}</span>
            </div>
        </div>
    </div>
</div>

<div class="pages-bar">
    <div class="page-row sidepage" *ngFor="let page of returnPages(); let i = index" (click)="scrollToPage(i)">
        <div class="page-dot"></div>
        <span class="page-txt"></span>
    </div>
</div>