 <!-- Next Segment-------------------- -->
 <div class="landing-segment fit section">
    <div class="photo-holder video">
        <video width="100%" height="fit-content" autoplay="autoplay" loop="loop" playsinline [muted]="true" #videoPlayerAbout>
            <source src="assets/about-video6.mp4" type="video/mp4">
        </video>
    </div>
    <div class="main-holder height">
        <div class="absolute-holder">
            <div class="link-path mobile-hide">
                <span>{{ langServ.returnTranslate('menu_home') }}</span>
                <div class="line"></div>
                <span>{{ langServ.returnTranslate('menu_about') }}</span>
            </div>
            <div class="heading-holder">
                <span class="heading l">{{ langServ.returnTranslate('about_us_header') }}</span>
                <div class="number-bar mobile-hide">
                    <span class="number active">01</span>
                    <div class="line"></div>
                    <span class="number">03</span>
                </div>
            </div>
            <div class="info-card ">
                <div class="info">
                    <span class="heading s">{{ langServ.returnTranslate('about_us_heading_1') }}</span>
                    <p class="main-txt">{{ langServ.returnTranslate('about_us_body_1_1') }}</p>
                    <p class="main-txt">{{ langServ.returnTranslate('about_us_body_1_2') }}</p>
                </div>
            </div>
            <div class="info-s-card">
                <div class="img-holder ">
                    <div class="card-photo2 abo1"></div>
                </div>
            </div>
            <div class="info-s-card margin">
                <div class="img-holder ">
                    <div class="card-photo2 abo1-2"></div>
                </div>            
            </div>
        </div>
    </div>
</div>
<!-- Next Segment 2-------------------- -->
<div class="main-segment section">
    <div class="main-holder boss">
        <div class="boss-holder">
            <div class="profile-photo"></div>
            <div class="info-holder">
                <span class="heading xs mobile-arrange-header">{{ langServ.returnTranslate('about_us_heading_2') }}</span>
                <span class="heading m boss">{{ langServ.returnTranslate('about_us_heading_2_1') }}</span>
                <div class="quote-holder">
                    <span>	&ldquo;</span>
                    <span class="quote">{{ langServ.returnTranslate('about_us_quote') }}</span>
                    <span> &rdquo;</span>
                </div>
                <span class="main-txt">{{ langServ.returnTranslate('about_us_body_2') }}</span> 
            </div>                       
        </div>
        <!-- <div class="info-s-card">
            <div class="img-holder ">
                <div class="card-photo2 abo1"></div>
            </div>
        </div>
        <div class="info-s-card">
            <div class="img-holder ">
                <div class="card-photo2 abo1"></div>
            </div>
        </div> -->
        <!--ВАЖНО не трий-->
        <div class="info-card right ">

            <div class="img-holder">
                <div class="card-photo2 boss2"></div>
            </div>
        </div>  
    </div>      
</div>

<!-- Next Segment 3-------------------- -->
<div class="main-segment section">
    <div class="main-holder">
        <div class="info-card ">
            <div class="info">
                <span class="heading s">{{ langServ.returnTranslate('about_us_heading_3') }}</span>
                <span class="main-txt">{{ langServ.returnTranslate('about_us_body_3') }}</span>
            </div>
            <div class="img-holder" (swipeleft)="swipeCurrentPhoto('right')" (swiperight)="swipeCurrentPhoto('left')">
                <div class="img-pagination" style="width:80px;left:calc(50% - 40px);">
                    <div class="page-row" [ngClass]="{ 'selected':currentPhoto == 1 }" (click)="switchCurrentPhoto(1)">
                        <div class="page-dot"></div>
                    </div>
                    <div class="page-row" [ngClass]="{ 'selected':currentPhoto == 2 }" (click)="switchCurrentPhoto(2)">
                        <div class="page-dot"></div>
                    </div> 
                </div>
                <div class="card-photo2 abo3-1-{{currentPhoto}}"></div>
            </div>
        </div> 
        <div class="info-s-card">
            <div class="img-holder ">
                <div class="card-photo2 abo3-2"></div>
            </div>
            <span class="heading xs">{{ langServ.returnTranslate('about_us_heading_4') }}</span>
            <span class="main-txt">{{ langServ.returnTranslate('about_us_body_4') }}</span>
        </div>
        <div class="info-s-card margin">
            <div class="img-holder ">
                <div class="card-photo2 abo3-3"></div>
            </div>
            <span class="heading xs">{{ langServ.returnTranslate('about_us_heading_5') }}</span>
            <span class="main-txt">{{ langServ.returnTranslate('about_us_body_5') }}</span>
        </div> 
    </div>      
</div>
<!-- Next Segment 4-------------------- -->
<div class="main-segment section">
    <div class="main-holder">
        <div class="info-card right ">
            <div class="info">
                <span class="heading s">{{ langServ.returnTranslate('about_us_heading_6') }}</span>
                <span class="main-txt">{{ langServ.returnTranslate('about_us_body_6') }}</span>
            </div>
            <div class="img-holder" (swipeleft)="swipeCurrentPhotoNext('right')" (swiperight)="swipeCurrentPhotoNext('left')">
                <div class="img-pagination">
                    <div class="page-row" [ngClass]="{ 'selected':currentPhotoNext == 1 }" (click)="switchCurrentPhotoNext(1)">
                        <div class="page-dot"></div>
                    </div>
                    <div class="page-row" [ngClass]="{ 'selected':currentPhotoNext == 2 }" (click)="switchCurrentPhotoNext(2)">
                        <div class="page-dot"></div>
                    </div>
                    <div class="page-row" [ngClass]="{ 'selected':currentPhotoNext == 3 }" (click)="switchCurrentPhotoNext(3)">
                        <div class="page-dot"></div>
                    </div>   
                </div>
                <div class="card-photo2 abo4-1-{{ currentPhotoNext }}"></div>
            </div>
        </div> 
        <div class="info-s-card left">
            <div class="img-holder ">
                <div class="card-photo2 abo4-3"></div>
            </div>
            <span class="heading xs">{{ langServ.returnTranslate('about_us_heading_7') }}</span>
            <span class="main-txt">{{ langServ.returnTranslate('about_us_body_7') }}</span>
        </div>
        <div class="info-s-card left margin">
            <div class="img-holder ">
                <div class="card-photo2 abo4-2"></div>
            </div>
            <span class="heading xs">{{ langServ.returnTranslate('about_us_heading_8') }}</span>
            <span class="main-txt">{{ langServ.returnTranslate('about_us_body_8') }}</span>
        </div> 
    </div>      
</div>
<!-- Next Segment-------------------- -->
<div class="project-segment section">
    <div class="project-holder marge-top">
        <div class="heading-holder">
            <span class="heading l">{{ langServ.returnTranslate('projects_heading') }}</span>
            <div class="number-bar">
                <span class="number active">03</span>
                <div class="line"></div>
                <span class="number">21</span>
            </div>
            <div class="btn right" routerLink="/projects">
                <div class="next-ic"></div>
                <span class="btn-txt">{{ langServ.returnTranslate('projects_view_all_btn') }}</span>
            </div>
        </div>
        <div class="project-row">
            <div class="project-card" *ngFor="let project of projects; let i = index">
                <div class="img-holder " [routerLink]="returnProjectLink(project)">
                    <div class="card-photo" [ngStyle]="{ 'background-image':'url(assets/projects/pro' + project.uid + '-cov-img.png)' }"></div>
                </div>
                <div class="project-info">
                    <span class="heading xxs" [routerLink]="returnProjectLink(project)">
                        {{ returnProjectTitle(project) }}
                    </span>
                    <div class="tag-holder">
                        <div class="tag {{tag}}" *ngFor="let tag of project.tags">
                            <span *ngIf="tag == 'design'" routerLink="/projects/design">{{ langServ.returnTranslate('tag_design') }}</span>
                            <span *ngIf="tag == 'software'" routerLink="/projects/software">{{ langServ.returnTranslate('tag_software') }}</span>
                            <span *ngIf="tag == 'hardware'" routerLink="/projects/hardware">{{ langServ.returnTranslate('tag_hardware') }}</span>
                            <span *ngIf="tag == 'network'" routerLink="/projects/network">{{ langServ.returnTranslate('tag_network') }}</span>
                            <span *ngIf="tag == 'video'" routerLink="/projects/video">{{ langServ.returnTranslate('tag_video') }}</span>
                            <span *ngIf="tag == 'cloud'" routerLink="/projects/cloud">{{ langServ.returnTranslate('tag_cloud') }}</span>
                        </div>   
                    </div>
                </div>
            </div>
        </div>
        <div class="btn left desktop-hide" routerLink="/projects">
            <div class="next-ic"></div>
            <span class="btn-txt">{{ langServ.returnTranslate('projects_view_all_1_btn') }}</span>
        </div>
    </div>
</div>

<!-- <div class="scroll-btn" (click)="NextPage()" *ngIf="currentPage < 4">
    <span class="scroll-btn-txt">следваща</span>
    <div class="scroll-ic"></div>
</div> -->

<!-- <div class="pages-bar">
    <div class="page-row sidepage selected" (click)="scrollToPage(0)">
        <div class="page-dot"></div>
        <span class="page-txt"></span>
    </div>
    <div class="page-row sidepage" (click)="scrollToPage(1)">
        <div class="page-dot"></div>
        <span class="page-txt"></span>
    </div>
    <div class="page-row sidepage" (click)="scrollToPage(2)">
        <div class="page-dot"></div>
        <span class="page-txt"></span>
    </div>
    <div class="page-row sidepage" (click)="scrollToPage(3)">
        <div class="page-dot"></div>
        <span class="page-txt"></span>
    </div>
    <div class="page-row sidepage" (click)="scrollToPage(4)">
        <div class="page-dot"></div>
        <span class="page-txt"></span>
    </div>
</div> -->