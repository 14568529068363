import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Location} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {
  project:any;
  currentProject:any;
  currentPage:any = 0;
  
  @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    this.doScroll(event);
  }

  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    this.doScroll(event);
  }

  @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
    this.doScroll(event);
  }

  @HostListener('wheel', ['$event']) onMouseWheelEdge(event: any) {
    this.doScroll(event);
  }

  constructor(private activeRoute: ActivatedRoute, private http:HttpClient, public langServ:LanguageService, private _location:Location) { }

  ngOnInit() {
    this.activeRoute.params.subscribe(params => { 
      this.project = params.id; 
      this.GetProject();
    });
    // this.window.scrollTo(0, 0);
    setTimeout(function(){
      var page_list = document.getElementsByClassName('sidepage');
      page_list[0].className = "page-row sidepage selected";
      this.currentPage = 0;
    }, 400);
  }

  doScroll(event) {
    // if(this.window.innerWidth > 1080 && this.window.innerHeight > 769){
    //   for(let i = 0; i < this.returnPages().length; i++){
    //     if(i == 0){
    //       if(document.body.scrollTop < 981 && this.currentPage != 0){
    //         var page_list = document.getElementsByClassName('sidepage');
    //         for(var pi = 0; pi <  page_list.length; pi++) { page_list[pi].className = "page-row sidepage"; }
    //         page_list[i].className= "page-row sidepage selected";
    //         this.currentPage = 0;
    //       }
    //     } else {
    //       if(document.body.scrollTop > (i * 981) && document.body.scrollTop < ((i + 1) * 981) && this.currentPage != i) {
    //         var page_list = document.getElementsByClassName('sidepage');
    //         for(var pi = 0; pi <  page_list.length; pi++) { page_list[pi].className = "page-row sidepage"; }
    //         page_list[i].className= "page-row sidepage selected";
    //         this.currentPage = i;
    //       }
    //     }
    //   }
    // }
  }

  scrollToPage(page) {
    let pageDifference = Math.abs(page - this.currentPage);
    var page_list = document.getElementsByClassName('sidepage');
    for(var i = 0; i <  page_list.length; i++) { page_list[i].className = "page-row sidepage"; }
    page_list[page].className= "page-row sidepage selected";
    var elem = document.getElementsByClassName('section')[page];
    var calc = page * document.body.offsetHeight;
  
    elem.scrollIntoView({behavior: "smooth", block: "center"});
    this.currentPage = page;
  }

  goBack() { this._location.back(); }
  NextPage(){ if(this.currentPage < document.getElementsByClassName('section').length) { this.scrollToPage(this.currentPage + 1); } }

  GetProject() {
    this.http.get("https://axion.solutions/api/service.php?action=get_project&id=" + this.project).subscribe(
    response => { 
      this.currentProject = response['project']; 
      this.currentProject.descriptions_bg = this.currentProject.description_bg.split(";");
      this.currentProject.descriptions_en = this.currentProject.description_en.split(";");
    },
    error => {  });
  }

  returnProjectTitle() {
    if(this.langServ.returnLanguage() == "bg") { return this.currentProject.title_bg; }
    else { return this.currentProject.title_en; }
  }

  returnDescriptions() {
    if(this.langServ.returnLanguage() == "bg") { return this.currentProject.descriptions_bg; }
    else { return this.currentProject.descriptions_en; }
  }

  returnPages() {
    return document.getElementsByClassName('section');
  }

}
