<!-- Next Segment-------------------- -->
 <div class="landing-segment fit ">
    <div class="photo-holder service"></div>
    <div class="main-holder">
        <div class="link-path mobile-hide">
            <span routerLink="/home">{{ langServ.returnTranslate('quick_page_home') }}</span>
            <div class="line"></div>
            <span routerLink="/services">{{ langServ.returnTranslate('quick_page_services') }}</span>
        </div>
        <div class="services-holder">
            <div class="card-holder">
                <div class="service-row">
                    <div routerLink="/service/video" class="services-card">
                        <img src="assets/ic/video-ic.svg" alt="" class="green-glow">
                        <div class="heading xs">{{ langServ.returnTranslate('services_title_1') }}</div>
                        <span class="main-txt">{{ langServ.returnTranslate('services_body_1') }}</span>
                    </div>
                    <div routerLink="/service/software" class="services-card">
                        <img src="assets/ic/software-ic.svg" alt="" class="green-glow">
                        <div class="heading xs">{{ langServ.returnTranslate('services_title_2') }}</div>
                        <span class="main-txt">{{ langServ.returnTranslate('services_body_2') }}</span>
                    </div>
                </div>
                <div class="service-row">
                    <div routerLink="/service/hardware" class="services-card">
                        <img src="assets/ic/hardware-ic.svg" alt="" class="green-glow">
                        <div class="heading xs">{{ langServ.returnTranslate('services_title_3') }}</div>
                        <span class="main-txt">{{ langServ.returnTranslate('services_body_3') }}</span>
                    </div>
                    <div routerLink="/service/design" class="services-card">
                        <img src="assets/ic/design-ic.svg" alt="" class="green-glow">
                        <div class="heading xs">{{ langServ.returnTranslate('services_title_4') }}</div>
                        <span class="main-txt">{{ langServ.returnTranslate('services_body_4') }}</span>
                    </div>
                </div>
                <div class="service-row">
                    <div routerLink="/service/cloud" class="services-card">
                        <img src="assets/ic/cloud-ic.svg" alt="" class="green-glow">
                        <div class="heading xs">{{ langServ.returnTranslate('services_title_5') }}</div>
                        <span class="main-txt">{{ langServ.returnTranslate('services_body_5') }}</span>
                    </div>
                    <div routerLink="/service/network" class="services-card">
                        <img src="assets/ic/network-ic.svg" alt="" class="green-glow">
                        <span class="heading xs">{{ langServ.returnTranslate('services_title_6') }}</span>
                        <span class="main-txt">{{ langServ.returnTranslate('services_body_6') }}</span>
                    </div>
                </div>
                
            </div>    
        </div>   
    </div>
</div>
<!-- <div class="social-bar">
    <div class="social-btn link-ic"></div>
    <div class="social-btn fb-ic"></div>
    <div class="social-btn insta-ic"></div>
    <div class="social-btn youtube-ic"></div>
</div> -->
<!-- <div class="pages-bar">
    <div class="page-row selected" onclick="scrollToSection(0)">
        <div class="page-dot"></div>
        <span class="page-txt">Начало</span>
    </div>
    <div class="page-row" onclick="scrollToSection(1)">
        <div class="page-dot"></div>
        <span class="page-txt">Ново</span>
    </div>
</div> -->