import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  lang:any = "bg";
  languageObject:any;
  loadedLanguage:any = false;

  constructor(private http:HttpClient) {
    this.GetLanguage();
  }

  GetLanguage() {
    console.log("TRYING TO GET LANGUAGE");
    this.http.get("assets/language/lang_" + this.lang + ".json").subscribe(
    response => { 
      this.languageObject = response;
      this.loadedLanguage = true;
      console.log("LANGUAGE LOADED");
    },
    error => {  });
  }
  
  switchLanguage(lang) {
    this.lang = lang;
    this.GetLanguage();
  }

  returnLanguage() { return this.lang; }

  returnTranslate(lbl) {
    return this.languageObject[lbl];
  }

  returnLanguageLoaded() { return this.loadedLanguage; }
}
