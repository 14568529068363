import { Component, OnInit, AfterViewInit, HostListener, Inject } from '@angular/core';
import { LanguageService } from '../language.service';
import { Meta, Title } from '@angular/platform-browser';


declare var self;
@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {
  viewingVideo:any = false;
  currentPage:any = 0;
  loaded:any = false;

  

  @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    this.doScroll(event);
  }

  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    this.doScroll(event);
  }

  @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
    this.doScroll(event);
  }

  @HostListener('wheel', ['$event']) onMouseWheelEdge(event: any) {
    this.doScroll(event);
  }

  viewVideo(id) { this.viewingVideo = true; }
  closeVideo() { this.viewingVideo = false; }

constructor( public langServ:LanguageService,  private title: Title, private meta: Meta) {
    self = this;
  }

  ngOnInit() {
    setTimeout(function(){
      self.loaded = true;
    }, 1500);
    // this.window.scrollTo(0, 0);
    this.title.setTitle('Услуги');
    this.meta.updateTag({ name: 'description', content: 'Извършваме разнообразие от услуги, с които създаваме персонални разработки и иновации за вашия бизнес и постигаме оптимизацията му' });
    this.meta.updateTag({ property: 'og:image', content: 'https://axion.solutions/axion-hero.jpg' });
    this.meta.updateTag({ property: 'og:title', content: 'Услуги' });
    this.meta.updateTag({ property: 'og:description', content: 'Извършваме разнообразие от услуги, с които създаваме персонални разработки и иновации за вашия бизнес и постигаме оптимизацията му' });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:url', content: 'https://axion.solutions/services' });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:locale', content: 'bg' });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ name: 'Copyright', content: 'Copyright © 2020 Axion Solutions LTD. All rights reserved' });
    this.meta.updateTag({ property: 'fb:app_id', content: '160295511063956' });
    this.meta.updateTag({ property: 'og:site_name', content: 'AXION – Иновативни Бизнес Решения' });
  }

  doScroll(event) {
    // if(this.window.innerWidth > 1080 && this.window.innerHeight > 769){
    //   var event = this.window.event || event; // old IE support
    //   var delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)));
    //   console.log(delta);
    //   if(self.currentPage < document.getElementsByClassName('section').length - 1) { 
    //       if(delta < 0 ) { 
    //           self.scrollToPage(self.currentPage + 1);
    //           // setTimeout(function(){
    //           //     self.allowedToScroll = true;
    //           // }, 1000);
    //       }
    //   }
    //   if(self.currentPage > 0) { 
    //       if(delta > 0) { 
    //           self.scrollToPage(self.currentPage - 1);
    //           // self.allowedToScroll = false;
    //           // setTimeout(function(){
    //           //   self.allowedToScroll = true;
    //           // }, 1000);
    //       }
    //   }
    // }
  }

  scrollToPage(page) {
    let pageDifference = Math.abs(page - this.currentPage);
    var page_list = document.getElementsByClassName('page-row');
    for(var i = 0; i <  page_list.length; i++) { page_list[i].className = "page-row"; }
    page_list[page].className= "page-row selected";
    var elem = document.getElementsByClassName('section')[page];
    var calc = page * document.body.offsetHeight;
  
    elem.scrollIntoView({behavior: "smooth", block: "center"});
    this.currentPage = page;
  }

}
