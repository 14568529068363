import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { StorageServiceModule } from 'ngx-webstorage-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ServicesComponent } from './services/services.component';
import { ProjectsComponent } from './projects/projects.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ServiceComponent } from './service/service.component';
import { SafePipe } from './safe.pipe';
import { ProjectComponent } from './project/project.component';

// Import library module
import { NgxJsonLdModule } from '@ngx-lite/json-ld';

import { HashLocationStrategy, LocationStrategy, PathLocationStrategy, CommonModule } from '@angular/common';
import { NewProjectComponent } from './new-project/new-project.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ServicesComponent,
    ProjectsComponent,
    AboutUsComponent,
    ContactsComponent,
    ServiceComponent,
    SafePipe,
    ProjectComponent,
    NewProjectComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    StorageServiceModule,
    CommonModule,
    NgxJsonLdModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}, { provide: Window, useValue: window }],
  bootstrap: [AppComponent]
})
export class AppModule { }
