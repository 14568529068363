import { Component, OnInit, AfterViewInit, HostListener, ViewChild, ElementRef, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from '../language.service';
import { LocationStrategy, isPlatformBrowser } from '@angular/common' 
import { DomSanitizer } from '@angular/platform-browser';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  lang:any = "bg";

  name = 'Angular 5';

  viewingVideo:any = false;
  currentVideo:any = "";
  currentVideoId:any = 0;

  viewingNotVideo:any = false;
  viewingNotVideoUrl = "";
  viewedFeature:any;

  currentPage:any = 0;
  allowedToScroll = false;
  loaded:any = false;

  companyImage = 1;

  featured:any = [];
  projects:any = [];
  schema:any;

  testBrowser:boolean;

  @ViewChild('videoPlayer', {static: false}) videoplayer: ElementRef;
  @ViewChild('youtubePlayer', {static: false}) youtubeplayer: any;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if(this.viewingVideo) { this.closeVideo(); }
  }

  constructor(private http:HttpClient, public langServ:LanguageService, location:LocationStrategy,private sanitizer: DomSanitizer,  private title: Title, private meta: Meta, @Inject(PLATFORM_ID) platformId: string) {
    // history.pushState(null, null, window.location.href);  
    // location.onPopState(() => {
    //   // self.closeVideo();
    //   history.pushState(null, null, window.location.href);
    // });  
    this.testBrowser = isPlatformBrowser(platformId);

    this.schema = {
        "@context" : "https://schema.org",
        "@type" : "Organization",
        "name" : "AXION SOLUTIONS",
        "url" : "https://axion.solutions",
        "contactPoint" : [{
            "@type" : "ContactPoint",
            "telephone" : "+359 700 11 267",
            "contactType" : "Контакти"
        }],
        "sameAs" : [
            "https://www.facebook.com/AxionSolutionsLTD/",
            "https://www.instagram.com/axion.solutions.ltd/",
            "http://www.linkedin.com/company/axionsolutions/"
        ]
    }
  }


  viewVideo(uid, num, type) {
    if(this.testBrowser) {
      if(type == 'video') {
        this.viewingVideo = true; 
        this.currentVideo = 'https://www.youtube.com/embed/'+ uid +'?autoplay=1';
        this.currentVideoId = num;
        setTimeout(() => {
          let ytvideo = document.getElementById('youtube-player').getElementsByTagName("video")[0] as HTMLVideoElement;
          ytvideo.muted = true;         
          ytvideo.play();  
        }, 500); 
      } else {
        this.viewingNotVideo = true;
        this.viewingNotVideoUrl = uid;
      } 
    }
  }
  closeVideo() { this.viewingVideo = false;this.viewingNotVideo = false; }

  ngOnInit() {
    this.GetFeatured();
    this.GetLastProjects();
    // this.window.scrollTo(0, 0);
    // this.videoplayer.nativeElement.muted = true;
    // this.videoplayer.nativeElement.play();
 
    // setTimeout(function(){
    //   self.loaded = true;
    // }, 3000);

    this.title.setTitle('AXION – Иновативни бизнес решения с хардуерни и софтуерни разработки');
    this.meta.updateTag({ name: 'description', content: 'Създаваме персонализирани проекти, които включват хардуерни и софтуерни разработки, като подобряваме процесите във всеки бизнес' });
    this.meta.updateTag({ property: 'og:image', content: 'https://axion.solutions/axion-hero.jpg' });
    this.meta.updateTag({ property: 'og:title', content: 'AXION – Иновативни бизнес решения' });
    this.meta.updateTag({ property: 'og:description', content: 'Създаваме персонализирани проекти, които включват хардуерни и софтуерни разработки, като подобряваме процесите във всеки бизнес' });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:url', content: 'https://axion.solutions' });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:locale', content: 'bg' });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ name: 'Copyright', content: 'Copyright © 2020 Axion Solutions LTD. All rights reserved' });
    this.meta.updateTag({ property: 'fb:app_id', content: '160295511063956' });
    this.meta.updateTag({ property: 'og:site_name', content: 'AXION – Иновативни Бизнес Решения' });
  }

  scrollToPage(page) {
    if(this.testBrowser) {
      let pageDifference = Math.abs(page - this.currentPage);
      var page_list = document.getElementsByClassName('sidepage');
      for(var i = 0; i <  page_list.length; i++) { page_list[i].className = "page-row sidepage"; }
      page_list[page].className= "page-row sidepage selected";
      var elem = document.getElementsByClassName('section')[page];
      var calc = page * document.body.offsetHeight;
    
      elem.scrollIntoView({behavior: "smooth", block: "center"});
      this.currentPage = page;
    }
  }

  GetFeatured() {
    this.http.get("https://axion.solutions/api/service.php?action=get_featured").subscribe(
    response => { 
      this.featured = response['featured']
    },
    error => {  });
  }

  GetLastProjects() {
    this.http.get("https://axion.solutions/api/service.php?action=get_last_projects").subscribe(
    response => { 
      this.projects = response['projects'];
      for(let i = 0; i < this.projects.length; i++) { this.projects[i].tags = this.projects[i].tag.split(";"); }
    },
    error => {  });
  }

  NextPage(){ if(this.currentPage < 5) { this.scrollToPage(this.currentPage + 1); } }

  switchCompanyImage(img) { this.companyImage = img; }

  linkTo(link) {
    // window.location.href=link;  
    window.open(link, "_blank");
  }

  onReady(e) {
    this.youtubeplayer.mute();         
    this.youtubeplayer.playVideo();   
  }

  returnFeaturedTitle(feature) {
    if(this.langServ.returnLanguage() == "bg") { return feature.title_bg; }
    else { return feature.title_en; }
  }

  returnServices(){
    return ["video", "software", "hardware", "design", "cloud", "network"];
  }

  returnProjectTitle(project) {
    if(this.langServ.returnLanguage() == "bg") { return project.title_bg; }
    else { return project.title_en; }
  }

  returnPadZero( num, size ){
    return ( Math.pow( 10, size ) + ~~num ).toString().substring( 1 );
  }

  returnProjectLink(project) {
    return '/project/' + project.uid;
  }

  returnNonVideoUrl() { return this.sanitizer.bypassSecurityTrustResourceUrl(this.viewingNotVideoUrl); }

}
