import { Component, OnInit, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit() {
    //this.window.scrollTo(0, 0);
    this.title.setTitle('Контакти');
    this.meta.updateTag({ name: 'description', content: 'Свържете се с нас, за да обсъдим идеите ви и да създадем решението, което е най-подходящо за вашия случай.' });
    this.meta.updateTag({ property: 'og:image', content: 'https://axion.solutions/axion-hero.jpg' });
    this.meta.updateTag({ property: 'og:title', content: 'Контакти' });
    this.meta.updateTag({ property: 'og:description', content: 'Свържете се с нас, за да обсъдим идеите ви и да създадем решението, което е най-подходящо за вашия случай.' });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:url', content: 'https://axion.solutions/contacts' });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:locale', content: 'bg' });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ name: 'Copyright', content: 'Copyright © 2020 Axion Solutions LTD. All rights reserved' });
    this.meta.updateTag({ property: 'fb:app_id', content: '160295511063956' });
    this.meta.updateTag({ property: 'og:site_name', content: 'AXION – Иновативни Бизнес Решения' });
  }

}
