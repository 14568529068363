import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { LanguageService } from '../language.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Router, NavigationEnd } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
  projects:any;
  search:any = "";
  currentProjects:any;
  currentTag:any = "all";

  loadingProjects:any = true;

  constructor(public langServ:LanguageService, private http:HttpClient, private activeRoute: ActivatedRoute, private router: Router, @Inject(LOCAL_STORAGE) private storage: StorageService, private title: Title, private meta: Meta) {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      this.activeRoute.params.subscribe(params => {
        this.FilterProjectsTag(params.tag);
        document.body.scrollTo(0,0);
      });
    });
  }

  ngOnInit() {
    this.GetProjects();
    // this.window.scrollTo(0, 0);

    let last_project = this.storage.get('last_project');
    console.log(last_project);
    // // insert updated array to local storage
    // this.storage.set(STORAGE_KEY, currentTodoList);
    this.title.setTitle('Проекти');
    this.meta.updateTag({ name: 'description', content: 'Проектите ни са персонализирани, спрямо нуждите на бизнеса ви и обхващат хардуер, софтуер, видео аналитика, дизайн, мрежи и облачни пространства' });
    this.meta.updateTag({ property: 'og:image', content: 'https://axion.solutions/axion-hero.jpg' });
    this.meta.updateTag({ property: 'og:title', content: 'Проекти' });
    this.meta.updateTag({ property: 'og:description', content: 'Проектите ни са персонализирани, спрямо нуждите на бизнеса ви и обхващат хардуер, софтуер, видео аналитика, дизайн, мрежи и облачни пространства' });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:url', content: 'https://axion.solutions/projects' });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:locale', content: 'bg' });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ name: 'Copyright', content: 'Copyright © 2020 Axion Solutions LTD. All rights reserved' });
    this.meta.updateTag({ property: 'fb:app_id', content: '160295511063956' });
    this.meta.updateTag({ property: 'og:site_name', content: 'AXION – Иновативни Бизнес Решения' });
  }

  GetProjects() {
    this.loadingProjects = true;

    this.http.get("https://axion.solutions/api/service.php?action=get_projects").subscribe(
    response => { 
      this.projects = response['projects'];
      for(let i = 0; i < this.projects.length; i++) { this.projects[i].tags = this.projects[i].tag.split(";"); }
      this.currentProjects = this.projects;
      this.loadingProjects = false;
      setTimeout(() => {
        let last_project = this.storage.get('last_project');
        console.log(last_project);
        if(last_project.scrolledTo != undefined){
          document.querySelector('body').scrollTo(0,last_project.scrolledTo);
        }
        this.storage.set('last_project', { })
      }, 250);

    },
    error => {  });
  }

  FilterProjectsTag(tag) {
    this.currentTag = tag;
    if(tag == 'all') {
      this.currentProjects = this.projects;
    } else {
      this.currentProjects = [];
      for(let i = 0; i < this.projects.length; i++){
        let pass = false;
        for(let ti = 0; ti < this.projects[i].tags.length; ti++){
          if(this.projects[i].tags[ti] == tag) { pass = true; }
        }
        if(pass) { this.currentProjects.push(this.projects[i]); }
      }
    }
  }

  saveProject(i) { this.storage.set('last_project', { scrolledTo:document.body.scrollTop }); }

  ignoreCheckbox(event) {
    event.preventDefault();
    console.log("foo was called");
  }

  returnCurrentProjects() {
    return this.currentProjects.filter(project => this.returnProjectTitle(project).toLowerCase().indexOf(this.search.toLowerCase()) > -1);
  }

  returnProjectTitle(project) {
    if(this.langServ.returnLanguage() == "bg") { return project.title_bg; }
    else { return project.title_en; }
  }

  returnPadZero( num, size ){
    return ( Math.pow( 10, size ) + ~~num ).toString().substring( 1 );
  }

  returnProjectLink(project) {
    return '/project/' + project.uid;
  }

  returnChecked(tag) {
    if(this.currentTag == tag) { return true; } else { return false; }
  }

}
