<!-- Next Segment-------------------- -->
<div class="landing-segment" style="height:fit-content;">
    <div class="photo-holder service"></div>
    <div class="main-holder has-header">
        <div class="link-path mobile-hide">
            <span routerLink="/home">{{ langServ.returnTranslate('quick_page_home') }}</span>
            <div class="line"></div>
            <span>{{ langServ.returnTranslate('quick_page_projects') }}</span>
        </div>
        <div class="heading-holder" style="height: 40px; margin-bottom: 15px; margin-top: 15px;">
            <span class="heading l">{{ langServ.returnTranslate('projects_heading') }}</span>
            <div class="search-box">
                <div class="search-ic"></div>
                <label>{{ langServ.returnTranslate('search_label') }}</label>
                <input class="search" type="text" [(ngModel)]="search" />
            </div>
        </div>
        <div class="filters-holder" style="margin-bottom: 30px;">
            <div class="filter-ic"></div>
            <span class="heading s">{{ langServ.returnTranslate('filter_label') }}</span>
            <div class="checkbox-bar">
                <div class="checkbox-holder">
                    <label class="container" (click)="FilterProjectsTag('all')">
                        <input type="checkbox" [checked]="returnChecked('all')" (click)="ignoreCheckbox($event)">
                        <span class="checkmark"></span>
                        <span>{{ langServ.returnTranslate('filter_all_label') }}</span>
                    </label>
                </div>

                <div class="checkbox-holder">
                    <label class="container" (click)="FilterProjectsTag('video')">
                        <input type="checkbox" [checked]="returnChecked('video')" (click)="ignoreCheckbox($event)">
                        <span class="checkmark"></span>
                        <span>{{ langServ.returnTranslate('filter_video_label') }}</span>
                    </label>
                </div>

                <div class="checkbox-holder">
                    <label class="container" (click)="FilterProjectsTag('software')">
                        <input type="checkbox" [checked]="returnChecked('software')" (click)="ignoreCheckbox($event)">
                        <span class="checkmark"></span>
                        <span>{{ langServ.returnTranslate('filter_software_label') }}</span>
                    </label>
                </div>

                <div class="checkbox-holder">
                    <label class="container" (click)="FilterProjectsTag('hardware')">
                        <input type="checkbox" [checked]="returnChecked('hardware')" (click)="ignoreCheckbox($event)">
                        <span class="checkmark"></span>
                        <span>{{ langServ.returnTranslate('filter_hardware_label') }}</span>
                    </label>
                </div>

                <div class="checkbox-holder">
                    <label class="container" (click)="FilterProjectsTag('design')">
                        <input type="checkbox" [checked]="returnChecked('design')" (click)="ignoreCheckbox($event)">
                        <span class="checkmark"></span>
                        <span>{{ langServ.returnTranslate('filter_design_label') }}</span>
                    </label>
                </div>

                <div class="checkbox-holder">
                    <label class="container" (click)="FilterProjectsTag('cloud')">
                        <input type="checkbox" [checked]="returnChecked('cloud')" (click)="ignoreCheckbox($event)">
                        <span class="checkmark"></span>
                        <span>{{ langServ.returnTranslate('filter_cloud_label') }}</span>
                    </label>
                </div>

                <div class="checkbox-holder">
                    <label class="container" (click)="FilterProjectsTag('network')">
                        <input type="checkbox" [checked]="returnChecked('network')" (click)="ignoreCheckbox($event)">
                        <span class="checkmark"></span>
                        <span>{{ langServ.returnTranslate('filter_network_label') }}</span>
                    </label>
                </div>
            </div>
        </div>
</div>
<!-- Next Segment-------------------- -->
<div class="project-segment grid">
    <div class="project-holder">
        <div class="project-grid">
            <img *ngIf="loadingProjects" src="assets/loader/small-loader.svg" width="150px" height="150px" style="float: left; margin-left: calc(50% - 75px); margin-right: calc(50% - 75px);" />
            <div class="project-card" *ngFor="let project of returnCurrentProjects(); let i = index">
                <div class="img-holder light" (click)="saveProject(i)" [routerLink]="returnProjectLink(project)">
                    <div class="card-photo" [ngStyle]="{ 'background-image':'url(assets/projects/pro' + project.uid + '-cov-img.png)' }"></div>
                </div>
                <div class="project-info">
                    <span class="heading xxs" (click)="saveProject(i)" [routerLink]="returnProjectLink(project)">
                        {{ returnProjectTitle(project) }}
                    </span>
                    <div class="tag-holder">
                        <div class="tag {{tag}}" *ngFor="let tag of project.tags">
                            <span *ngIf="tag == 'design'" (click)="FilterProjectsTag('design')">{{ langServ.returnTranslate('tag_design') }}</span>
                            <span *ngIf="tag == 'software'" (click)="FilterProjectsTag('software')">{{ langServ.returnTranslate('tag_software') }}</span>
                            <span *ngIf="tag == 'hardware'" (click)="FilterProjectsTag('hardware')">{{ langServ.returnTranslate('tag_hardware') }}</span>
                            <span *ngIf="tag == 'network'" (click)="FilterProjectsTag('network')">{{ langServ.returnTranslate('tag_network') }}</span>
                            <span *ngIf="tag == 'video'" (click)="FilterProjectsTag('video')">{{ langServ.returnTranslate('tag_video') }}</span>
                            <span *ngIf="tag == 'cloud'" (click)="FilterProjectsTag('cloud')">{{ langServ.returnTranslate('tag_cloud') }}</span>
                        </div>   
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>