<div class="loader-animation" [ngClass]="{ 'loaded':returnLoaded() }"  *ngIf="langServ.returnLanguageLoaded()">
    <div class="animation-holder">
        <div class="outter-circle">
            <div class="rotate-circle"></div>
            <div class="logo-circle">
                <div class="dot-circle"></div>
            </div>
        </div> 
        <span class="number-holder"></span>
    </div>
</div>

<div class="nav-holder"  *ngIf="langServ.returnLanguageLoaded()">
  <div class="ax-ic" routerLink="/home" style="margin-left:0px;"></div>
  <div class="lang-select">
      <span class="lang" [ngClass]="{ 'selected': langServ.returnLanguage() == 'bg'}" (click)="langServ.switchLanguage('bg')" *ngIf="langServ.returnLanguage() == 'bg'">BG</span>
      <span class="lang" [ngClass]="{ 'selected': langServ.returnLanguage() == 'en'}" (click)="langServ.switchLanguage('en')">EN</span>
      <span class="lang" [ngClass]="{ 'selected': langServ.returnLanguage() == 'bg'}" (click)="langServ.switchLanguage('bg')" *ngIf="langServ.returnLanguage() == 'en'">BG</span>
  </div>
  <ul class="nav" *ngIf="langServ.returnLanguageLoaded()">
      <a href="/home" routerLink="/home" routerLinkActive="active">{{ langServ.returnTranslate('menu_home') }}</a>
      <a href="/services" routerLink="/services" routerLinkActive="active">{{ langServ.returnTranslate('menu_services') }}</a>
      <a href="/projects/all" routerLink="/projects/all" routerLinkActive="active">{{ langServ.returnTranslate('menu_projects') }}</a>
      <a href="/about-us" routerLink="/about-us" routerLinkActive="active">{{ langServ.returnTranslate('menu_about_us') }}</a>
      <a href="/contacts" routerLink="/contacts" routerLinkActive="active">{{ langServ.returnTranslate('menu_contacts') }}</a>
  </ul>
</div>

<!-- add active for open nav -->
<div class="fixed-nav" [ngClass]="{ 'active':menu, 'full': returnIsFullPage() }"  *ngIf="langServ.returnLanguageLoaded()">
  <div class="ax-ic" routerLink="/home"></div>
  <div class="nav-menu" (click)="switchMenu()">
      <div class="line1"></div>
      <div class="line2"></div>
      <div class="line3"></div>
  </div>
  <ul class="rows-holder desktop-hide"  *ngIf="langServ.returnLanguageLoaded()">
      <li routerLink="/home" routerLinkActive="active" (click)="closeMenu()">{{ langServ.returnTranslate('menu_home') }}</li>
      <li routerLink="/services" routerLinkActive="active" (click)="closeMenu()">{{ langServ.returnTranslate('menu_services') }}</li>
      <li routerLink="/projects/all" routerLinkActive="active" (click)="closeMenu()">{{ langServ.returnTranslate('menu_projects') }}</li>
      <li routerLink="/about-us" routerLinkActive="active" (click)="closeMenu()">{{ langServ.returnTranslate('menu_about_us') }}</li>
      <li routerLink="/contacts" routerLinkActive="active" (click)="closeMenu()">{{ langServ.returnTranslate('menu_contacts') }}</li>
  </ul>
  <div class="lang-holder desktop-hide">
      <li class="lang" [ngClass]="{ 'active': langServ.returnLanguage() == 'bg'}" (click)="langServ.switchLanguage('bg')">BG</li>
      <li class="lang" [ngClass]="{ 'active': langServ.returnLanguage() == 'en'}" (click)="langServ.switchLanguage('en')">EN</li>
  </div>

    <div class="filter-header" *ngIf="currentPage == '/service/video' || currentPage == '/service/software' || currentPage == '/service/hardware' || currentPage == '/service/design' || currentPage == '/service/cloud' || currentPage == '/service/network'">
        <span routerLink="/service/video" routerLinkActive="active">{{ langServ.returnTranslate('services_title_1') }}</span>
        <span routerLink="/service/software" routerLinkActive="active">{{ langServ.returnTranslate('services_title_2') }}</span>
        <span routerLink="/service/hardware" routerLinkActive="active">{{ langServ.returnTranslate('services_title_3') }}</span>
        <span routerLink="/service/design" routerLinkActive="active">{{ langServ.returnTranslate('services_title_4') }}</span>
        <span routerLink="/service/cloud" routerLinkActive="active">{{ langServ.returnTranslate('services_title_5') }}</span>
        <span routerLink="/service/network" routerLinkActive="active">{{ langServ.returnTranslate('services_title_6') }}</span>
    </div>

    <div class="filter-header" *ngIf="currentPage == '/projects/all' || currentPage == '/projects/video' || currentPage == '/projects/software' || currentPage == '/projects/hardware' || currentPage == '/projects/design' || currentPage == '/projects/cloud' || currentPage == '/projects/network'">
        <span routerLink="/projects/video" routerLinkActive="active">{{ langServ.returnTranslate('services_title_1') }}</span>
        <span routerLink="/projects/software" routerLinkActive="active">{{ langServ.returnTranslate('services_title_2') }}</span>
        <span routerLink="/projects/hardware" routerLinkActive="active">{{ langServ.returnTranslate('services_title_3') }}</span>
        <span routerLink="/projects/design" routerLinkActive="active">{{ langServ.returnTranslate('services_title_4') }}</span>
        <span routerLink="/projects/cloud" routerLinkActive="active">{{ langServ.returnTranslate('services_title_5') }}</span>
        <span routerLink="/projects/network" routerLinkActive="active">{{ langServ.returnTranslate('services_title_6') }}</span>
    </div>
</div>
<div class="cookie-bar" [ngClass]="{ 'accepted':gdpr == '1' }"  *ngIf="langServ.returnLanguageLoaded()">
  <p [innerHTML]="langServ.returnTranslate('cookie_bar')">
  </p>
  <!-- <div class="close-btn">
      <div class="outer">
          <div class="inner">
            <label>затвори</label>
          </div>
      </div>
  </div>  -->
  <div class="btn-footer right" (click)="SaveGDPR()">
      <span class="btn-txt">{{ langServ.returnTranslate('accept_btn') }}</span>
  </div>
</div>

<router-outlet (activate)="onActivate($event)"></router-outlet>

<!-- Next Segment-------------------- -->
<div class="contacts-segment section" *ngIf="currentPage != '/services' && currentPage.indexOf('/project') == -1 && langServ.returnLanguageLoaded()" >
    <div #map id="map" class="photo-holder"></div>
    <div class="contacts-holder">
        <span class="heading l">{{ langServ.returnTranslate('contact_us_heading') }}</span>
        <div class="contacts-bar">
            <div class="contacts-row long">
                <span class="heading xxs">{{ langServ.returnTranslate('label_address') }}</span>
                <span class="main-txt">{{ langServ.returnTranslate('label_heading_address') }}</span>
            </div>
            <div class="contacts-row">
                <a class="heading xxs" href="mailto:office@axion.solutions">{{ langServ.returnTranslate('label_email') }}</a>
                <span class="main-txt">{{ langServ.returnTranslate('label_heading_email') }}</span>
            </div>
            <div class="contacts-row">
                <a class="heading xxs" href="tel:+35970011267">{{ langServ.returnTranslate('label_phone') }}</a>
                <span class="main-txt">{{ langServ.returnTranslate('label_heading_phone') }}</span>
            </div>
            <a class="btn right" href="https://www.google.com/maps/place/Axion+Solutions+Ltd./@42.138777,24.786283,17z/data=!4m5!3m4!1s0x14acd11c7b02f3eb:0x480c640f04e94244!8m2!3d42.138777!4d24.788477" target="_blank" style="text-decoration: none;">
                <div class="next-ic"></div>
                <span class="btn-txt">{{ langServ.returnTranslate('google_maps_btn') }}</span>
            </a>
        </div>
    </div>
    <div class="form-holder">
        <div class="rows-holder">
            <span class="heading m">{{ langServ.returnTranslate('idea_heading') }}</span>
            <span class="main-txt">{{ langServ.returnTranslate('idea_body') }}</span>
            <div class="input-holder">
                <input class="row" [(ngModel)]="contactForm.email" [ngClass]="{ 'has-content':contactForm.email.length > 0 }" type="text" placeholder="">
                <label>{{ langServ.returnTranslate('contact_form_label_email') }}</label>
                <div class="input-light"></div>
            </div>
            <div class="input-holder">
                <input class="row" [(ngModel)]="contactForm.name" [ngClass]="{ 'has-content':contactForm.name.length > 0 }" type="text" placeholder="">
                <label>{{ langServ.returnTranslate('contact_form_label_name') }}</label>
                <div class="input-light"></div>
            </div>
            <div class="input-holder">
                <input class="row" [(ngModel)]="contactForm.title" [ngClass]="{ 'has-content':contactForm.title.length > 0 }" type="text" placeholder="">
                <label>{{ langServ.returnTranslate('contact_form_label_title') }}</label>
                <div class="input-light"></div>
            </div>
        </div>
        <div class="message-holder">
            <div class="textarea-holder">
                <textarea class="contact-msg" [(ngModel)]="contactForm.message" [ngClass]="{ 'has-content':contactForm.message.length > 0 }"></textarea> 
                <div class="input-light"></div> 
                <label>{{ langServ.returnTranslate('contact_form_label_message') }}</label>
            </div>
        </div>
        <div class="btn-footer right phone-hide" (click)="sendMail()" style="margin-top:20px;">
            <div class="send-ic"></div>
            <span class="btn-txt" style="height: 40px; line-height: 40px; margin-left: 20px;">{{ langServ.returnTranslate('send_us_message_btn') }}</span>
        </div>
        <div class="btn-footer left desktop-hide" style="margin-top:20px;" (click)="sendMail()">
            <div class="send-ic"></div>
            <span class="btn-txt" style="height: 40px; line-height: 40px; margin-left: 20px;">{{ langServ.returnTranslate('send_us_message_btn') }}</span>
        </div>
    </div>
</div>
<!-- Next Segment-------------------- -->
<div class="footer-segment"  *ngIf="langServ.returnLanguageLoaded() && currentPage.indexOf('/project') == -1">
    <div class="footer-holder">
        <div class="group"  *ngIf="langServ.returnLanguageLoaded()">
            <span class="heading xxs">{{ langServ.returnTranslate('footer_connections_heading') }}</span>
            <span class="footer-row" routerLink="/home">{{ langServ.returnTranslate('menu_home') }}</span>
            <span class="footer-row" routerLink="/services">{{ langServ.returnTranslate('menu_services') }}</span>
            <span class="footer-row" routerLink="/projects">{{ langServ.returnTranslate('menu_projects') }}</span>
            <span class="footer-row" routerLink="/about-us">{{ langServ.returnTranslate('menu_about_us') }}</span>
            <span class="footer-row" routerLink="/contacts">{{ langServ.returnTranslate('menu_contacts') }}</span>
        </div>
        <div class="group long">
            <span class="heading xxs">{{ langServ.returnTranslate('footer_information_heading') }}</span>
            <span class="footer-row" (click)="linkTo('https://www.axion.solutions/docs/privacy_policy.docx')">{{ langServ.returnTranslate('footer_protect_privacy_label') }}</span>
        </div>
        <div class="subscribe-holder">
            <span class="heading xxs">{{ langServ.returnTranslate('footer_bulletin_label') }}</span>
            <div class="input-holder">
                <input class="row" [(ngModel)]="subscribe_email" type="text" placeholder="">
                <label >{{ langServ.returnTranslate('contact_form_label_email') }}</label>
                <div class="input-light"></div>
            </div>
            <div class="btn-footer left" (click)="subscribeMail()">
                <span class="btn-txt">{{ langServ.returnTranslate('sign_me_up_bulletin_btn') }}</span>
                <div class="subscribe-ic"></div>
            </div>
        </div>
        <div class="credits-holder">
            <span class="main-txt">Copyright © 2020 Axion Solutions LTD. All rights reserved.</span>
            <div class="social-footer">
                <div class="social-btn link-ic" (click)="linkTo('https://www.linkedin.com/company/hi-tech-electronic-ltd')"></div>
                <div class="social-btn fb-ic" (click)="linkTo('https://www.facebook.com/AxionSolutionsLTD')"></div>
                <div class="social-btn insta-ic" (click)="linkTo('https://www.instagram.com/axion.solutions.ltd/')"></div>
                <div class="social-btn youtube-ic" (click)="linkTo('https://www.youtube.com/channel/UCsNFc9-33Ni2vHFcd8odUjA')"></div>
            </div>
        </div>
    </div>
</div>

<div class="contact-popup" [ngClass]="{ 'active':sentMessage }" (click)="sentMessage = false" *ngIf="currentPage != '/project'">
    <div class="contact-icon">
        <div class="check"></div>
    </div>
    <div class="contact-msg" *ngIf="sentMessageType == 1">{{ langServ.returnTranslate('contact_popup_message_sent') }}</div>
    <div class="contact-msg" *ngIf="sentMessageType == 2">{{ langServ.returnTranslate('contact_popup_subscribed') }}</div>
</div>