<div class="landing-segment section" *ngIf="langServ.returnLanguageLoaded()">
    <div class="photo-holder" style="height:100%;opacity: 0.2;">
        <video id="video-bg" width="100%" height="100%" style="object-fit:cover;" autoplay="autoplay" loop="loop" playsinline [muted]="true" #videoPlayer>
            <source src="assets/shapes.mp4" type="video/mp4">
        </video>
    </div>
    <div class="landing-info-holder">
        <div class="landing-txt-holder">
            <span class="heading l">
                <span class="axion"></span>
                <span class="slogan" id="slogan_text">{{ langServ.returnTranslate('slogan_text') }}</span>
            </span>
        </div>
        <p class="landing-info-txt">
           {{ langServ.returnTranslate('slogan_subtext') }}
        </p>
        <a routerLink="/projects/all" class="btn">
            <div class="next-ic"></div>
            <span class="btn-txt" >{{ langServ.returnTranslate('btn_view_projects') }}</span>
        </a>
    </div>
    <!-- <div class="photo-holder" style="height:100%;opacity: 0.5;">
        <video id="video-bg" width="100%" height="100%" style="object-fit:cover;" autoplay="autoplay" loop="loop" playsinline [muted]="true" #videoPlayer>
            <source src="assets/shapes.mp4" type="video/mp4">
        </video>
    </div>
    <div class="landing-txt-holder">
        <span class="heading l">
            <span class="axion"></span>
            <span style="font-size: 20px;color: #FFFFFF;font-weight: 500;line-height: 30px;text-transform: uppercase;float:left;">{{ langServ.returnTranslate('slogan_text') }}</span>
        </span>
    </div> -->
    <!-- <span class="axion"></span>
    <div class="mobile-landing desktop-hide" [ngClass]="{ 'bg':langServ.returnLanguage() == 'bg', 'en':langServ.returnLanguage() == 'en' }"></div> -->
    <!-- <div class="social-bar">
        <div class="social-btn link-ic" (click)="linkTo('https://www.linkedin.com/company/hi-tech-electronic-ltd')"></div>
        <div class="social-btn fb-ic" (click)="linkTo('https://www.facebook.com/HiTechElectronicLTD')"></div>
        <div class="social-btn insta-ic" (click)="linkTo('https://www.instagram.com/hi.tech.electronic.ltd')"></div>
        <div class="social-btn youtube-ic" (click)="linkTo('https://www.youtube.com/channel/UCsNFc9-33Ni2vHFcd8odUjA')"></div>
    </div>
    <div class="featured">
        <span class="heading m">{{ langServ.returnTranslate('title_featured') }}</span>
        <div class="carousel">
            <div class="card-holder" *ngFor="let feature of featured; let i = index" (click)="viewVideo(feature.video_url, i, feature.featured_type)">
                <div class="card light">
                    <div class="play-btn" *ngIf="feature.featured_type == 'video'"></div>
                    <div class="card-photo" [ngStyle]="{ 'background-image':'url('+ feature.thumbnail +')' }"></div>
                </div>
                <span class="heading xs">{{ returnFeaturedTitle(feature) }}</span>
            </div>
        </div>
    </div> -->
</div>
<div class="pages-bar" *ngIf="langServ.returnLanguageLoaded()">
    <div class="page-row sidepage selected" (click)="scrollToPage(0)">
        <div class="page-dot"></div>
        <span class="page-txt">{{ langServ.returnTranslate('quick_page_home') }}</span>
    </div>
    <!-- <div class="page-row" (click)="scrollToPage(1)">
        <div class="page-dot"></div>
        <span class="page-txt">{{ langServ.returnTranslate('quick_page_news') }}</span>
    </div> -->
    <div class="page-row sidepage" (click)="scrollToPage(1)">
        <div class="page-dot"></div>
        <span class="page-txt">{{ langServ.returnTranslate('quick_page_services') }}</span>
    </div>
    <div class="page-row sidepage" (click)="scrollToPage(2)">
        <div class="page-dot"></div>
        <span class="page-txt">{{ langServ.returnTranslate('quick_page_projects') }}</span>
    </div>
    <div class="page-row sidepage" (click)="scrollToPage(3)">
        <div class="page-dot"></div>
        <span class="page-txt">{{ langServ.returnTranslate('quick_page_about_1') }}</span>
    </div>
    <div class="page-row sidepage" (click)="scrollToPage(4)">
        <div class="page-dot"></div>
        <span class="page-txt">{{ langServ.returnTranslate('quick_page_contacts') }}</span>
    </div>
</div>

<div class="pop-up-holder" *ngIf="viewingNotVideo" [ngClass]="{ 'active':viewingNotVideo }">
    <div class="back-drop"></div>
    <div class="pop-up txt">
        <div class="pop-up-nav">
            <div class="nav-heading-holder">
                <span class="nav-heading"></span>
            </div>
            <div class="close-btn" (click)="closeVideo()">
                <div class="outer">
                    <div class="inner">
                      <label>затвори</label>
                    </div>
                  </div>
            </div>   
        </div>
        <div class="scrollable-content" style="height: calc(100% - 60px);">
            <iframe [src]="returnNonVideoUrl()" title="" style="width: 100%; height: 100%;border:0px;"></iframe>
        </div>
    </div>
</div>

<div class="pop-up-holder" [ngClass]="{ 'active':viewingVideo }" *ngIf="langServ.returnLanguageLoaded()">
    <div class="back-drop"></div>
    <div class="pop-up video">
        <div class="pop-up-nav">
            <div class="nav-heading-holder">
                <span class="nav-heading">{{ langServ.returnTranslate('title_video_modal') }}</span>
            </div>
            <div class="close-btn" (click)="closeVideo()">
                <div class="outer">
                    <div class="inner">
                      <label>{{ langServ.returnTranslate('close_btn') }}</label>
                    </div>
                  </div>
            </div>   
        </div>
        <div class="scrollable-content">
            <div class="video-holder" *ngIf="viewingVideo">
                <iframe id="youtube-player" #youtubeplayer width="100%"  height="100%" [src]="currentVideo | safe" (ready)="onReady($event)" frameborder="0" allow="accelerometer; autoplay;" autoplay="1" allowfullscreen></iframe>
                <div class="profile-holder">
                    <div class="profile-photo"></div>
                    <div class="txt-holder">
                        <span class="profile-name">AXION</span>
                        <span class="main-txt subscribers-txt">14 subscribers</span>
                    </div>
                    <div class="youtube-btn">{{ langServ.returnTranslate('subscribe_btn') }}</div>
                </div>
            </div>
            <div class="side-videos-holder">
                <div class="card-holder" *ngFor="let feature of featured; let i = index" [ngClass]="{ 'selected':currentVideoId == i }" (click)="viewVideo(feature.video_url, i, feature.featured_type)">
                    <div class="card light">
                        <div class="play-btn"></div>
                        <div class="card-photo" [ngStyle]="{ 'background-image':'url(' + feature.thumbnail + ')' }"></div>
                    </div>
                    <span class="heading xs">{{ returnFeaturedTitle(feature) }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Next Segment-------------------- -->
<!-- <div class="news-segment section">
    <div class="photo-holder new1"></div>
    <div class="news-holder">
        <div class="main-news">
            <div class="heading-holder">
                <span class="heading m">{{ langServ.returnTranslate('heading_news') }}</span>
                <div class="number-bar">
                    <span class="number active">01</span>
                    <div class="line"></div>
                    <span class="number">01</span>
                </div>
            </div>
            <div class="news-card new1">
                <div class="img-pagination" style="display:none;">
                    <div class="page-row selected">
                        <div class="page-dot"></div>
                    </div>
                    <div class="page-row">
                        <div class="page-dot"></div>
                    </div>
                    <div class="page-row">
                        <div class="page-dot"></div>
                    </div>    
                </div>
                <div class="side-bar">
                    <div class="sidebar-back-btn">
                        <div class="sidebar-ic back"></div>
                        <span class="sidebar-txt back">предишна</span>
                    </div>  
                    <div class="number-bar">
                        <span class="number active">01</span>
                        <div class="line"></div>
                        <span class="number">03</span>
                    </div>
                    <div class="sidebar-next-btn">
                        <span class="sidebar-txt next">следваща</span>
                        <div class="sidebar-ic next"></div>
                    </div>
                </div>
            </div>
            <span class="heading s">
                Парк "Бунарджика" с ново интелигентно осветление
            </span>
            <span class="main-txt">
                    Със съдействието на нашите партньори от "2R България" и "Драгиев и Ко" изпълнихме проект за Община Пловдив,
                    в който изградихме съвременно парково осветление на територията на хълм "Бунарджика". 
                    Разработените от нас хардуер и софтуер позволяват отдалечено управление и индивидуален контрол на осветителните тела, 
                    както и обратна информация при наличието на технически проблем.
                    Използваният иновативен подход в проекта допринася за съществена оптимизация в разхода на електроенергия.
            </span>
            <span class="heading xxs">Категории</span>
            <div class="tag hardware"><span>хардуерно прототипиране</span> </div>
            <div class="tag software"><span>софтуер</span></div>
        </div>
            <div class="side-news">
                <span class="heading s">още новини</span>
                <div class="side-card-holder">
                    <div class="side-card light">
                        <div class="card-photo new2"></div>
                    </div>
                    <span class="heading xs">
                        Система за автоматизация на сгради
                    </span>
                    <span class="main-txt">
                            Съвместно с партньорите ни от "Темпекс" разработихме система за домашна и индустрялна автоматизация. 
                            Тя позволява комбиниран контрол на множество подсистеми в сградата, като климатизация, осветление, 
                            видеонаблюдение, контрол на достъп, поливни системи, пожароизвестяване и др. 
                            Избраните технологични методи за изпълнение осигуряват сигурност, надежност и лесна употреба. 
                    </span>
                </div>
                <div class="side-card-holder">
                    <div class="side-card light">
                        <div class="card-photo new3"></div>
                    </div>
                    <span class="heading xs">
                            Първият по рода си автономен хотел в Пловдив
                    </span>
                </div>
            </div>
    </div>
</div> -->
<!-- Next Segment-------------------- -->
<div class="services-segment section" *ngIf="langServ.returnLanguageLoaded()">
    <div class="services-holder">
        <div class="heading-holder">
            <div class="heading l">{{ langServ.returnTranslate('heading_services') }}</div>
            <div class="number-bar">
                <span class="number active">06</span>
                <div class="line"></div>
                <span class="number">06</span>
            </div>
        </div>
        <div class="card-holder">
            <div class="service-row">
                <div class="services-card" routerLink="/service/video">
                    <img src="assets/ic/video-ic.svg" alt="" class="green-glow">
                    <div class="heading xs">{{ langServ.returnTranslate('services_title_1') }}</div>
                    <span class="main-txt">{{ langServ.returnTranslate('services_body_1') }} </span>
                </div>
                <div class="services-card" routerLink="/service/software">
                    <img src="assets/ic/software-ic.svg" alt="" class="green-glow">
                    <div class="heading xs">{{ langServ.returnTranslate('services_title_2') }}</div>
                    <span class="main-txt">{{ langServ.returnTranslate('services_body_2') }}</span>
                </div>
            </div>
            <div class="service-row">
                <div class="services-card" routerLink="/service/hardware">
                    <img src="assets/ic/hardware-ic.svg" alt="" class="green-glow">
                    <div class="heading xs">{{ langServ.returnTranslate('services_title_3') }}</div>
                    <span class="main-txt">{{ langServ.returnTranslate('services_body_3') }}</span>
                </div>
                <div class="services-card" routerLink="/service/design">
                    <img src="assets/ic/design-ic.svg" alt="" class="green-glow">
                    <div class="heading xs">{{ langServ.returnTranslate('services_title_4') }}</div>
                    <span class="main-txt">{{ langServ.returnTranslate('services_body_4') }}</span>
                </div>
            </div>
            <div class="service-row">
                <div class="services-card" routerLink="/service/cloud">
                    <img src="assets/ic/cloud-ic.svg" alt="" class="green-glow">
                    <div class="heading xs">{{ langServ.returnTranslate('services_title_5') }}</div>
                    <span class="main-txt">{{ langServ.returnTranslate('services_body_5') }}</span>
                </div>
                <div class="services-card" routerLink="/service/network">
                    <img src="assets/ic/network-ic.svg" alt="" class="green-glow">
                    <div class="heading xs">{{ langServ.returnTranslate('services_title_6') }}</div>
                    <span class="main-txt">{{ langServ.returnTranslate('services_body_6') }}</span>
                </div>
            </div>
            
        </div>    
    </div>
    <div class="banner">
        <div class="ic-holder">
            <img src="assets/ic/lamp-ic.svg" alt="" class="lamp-ic">
            <img src="assets/ic/puzzle-ic.svg" class="puzzle-ic red-glow" alt="" height="55px">
        </div>
        <span class="heading m">{{ langServ.returnTranslate('heading_offer') }}</span>
        <span class="main-txt">{{ langServ.returnTranslate('offer_body') }}</span>
        <div class="btn left" (click)="scrollToPage(4)">
            <div class="next-ic"></div>
            <span class="btn-txt">{{ langServ.returnTranslate('contact_us_btn_1') }}</span>
        </div>
    </div>
</div>
<!-- Next Segment-------------------- -->
<div class="project-segment section" *ngIf="langServ.returnLanguageLoaded()">
    <div class="project-holder">
        <div class="heading-holder">
            <span class="heading l">{{ langServ.returnTranslate('projects_heading') }}</span>
            <div class="number-bar">
                <span class="number active">03</span>
                <div class="line"></div>
                <span class="number">21</span>
            </div>
            <div class="btn right" routerLink="/projects/all">
                <div class="next-ic"></div>
                <span class="btn-txt">{{ langServ.returnTranslate('projects_view_all_btn') }}</span>
            </div>
        </div>
        <div class="project-row">
            <div class="project-card" *ngFor="let project of projects; let i = index">
                <!-- <span class="side-num s">{{ returnPadZero(i + 1, 2) }}</span> -->
                <div class="img-holder light" [routerLink]="returnProjectLink(project)">
                    <div class="card-photo" [ngStyle]="{ 'background-image':'url(assets/projects/pro' + project.uid + '-cov-img.png)' }"></div>
                </div>
                <div class="project-info">
                    <span class="heading xxs" [routerLink]="returnProjectLink(project)">
                        {{ returnProjectTitle(project) }}
                    </span>
                    <div class="tag-holder">
                        <div class="tag {{tag}}" *ngFor="let tag of project.tags">
                            <span *ngIf="tag == 'design'" routerLink="/projects/design">{{ langServ.returnTranslate('tag_design') }}</span>
                            <span *ngIf="tag == 'software'" routerLink="/projects/software">{{ langServ.returnTranslate('tag_software') }}</span>
                            <span *ngIf="tag == 'hardware'" routerLink="/projects/hardware">{{ langServ.returnTranslate('tag_hardware') }}</span>
                            <span *ngIf="tag == 'network'" routerLink="/projects/network">{{ langServ.returnTranslate('tag_network') }}</span>
                            <span *ngIf="tag == 'video'" routerLink="/projects/video">{{ langServ.returnTranslate('tag_video') }}</span>
                            <span *ngIf="tag == 'cloud'" routerLink="/projects/cloud">{{ langServ.returnTranslate('tag_cloud') }}</span>
                        </div>   
                    </div>
                </div>
            </div>
        </div>
        <div class="btn left desktop-hide" routerLink="/projects/all">
            <div class="next-ic"></div>
            <span class="btn-txt">{{ langServ.returnTranslate('projects_view_all_btn') }}</span>
        </div>
    </div>
    <div class="product-holder">
        <div class="heading-holder">
            <span class="heading l" id="heading_projects">{{ langServ.returnTranslate('product_heading') }}</span>
            <!-- <div class="number-bar">
                <span class="number active">02</span>
                <div class="line"></div>
                <span class="number">02</span>
            </div> -->
        </div>
        <div class="banner-l light2" (click)="linkTo('https://gps.axion.solutions')">
            <div class="banner-photo gps-background"></div>
            <div class="content-holder">
                <div class="logo-holder gps-logo"></div>
                <div class="description-holder">
                    <span class="heading s">
                        {{ langServ.returnTranslate('product_label_1') }}
                    </span>
                    <div class="btn">
                        <div class="next-ic"></div>
                        <span class="btn-txt">{{ langServ.returnTranslate('btn_more') }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="banner-l light2" (click)="linkTo('https://visionx.axion.solutions')">
            <div class="banner-photo visionx-background"></div>
            <div class="content-holder">
                <div class="logo-holder visionx-logo"></div>
                <div class="description-holder">
                    <span class="heading s">
                        {{ langServ.returnTranslate('product_label_2') }}
                    </span>
                    <div class="btn">
                        <div class="next-ic"></div>
                        <span class="btn-txt">{{ langServ.returnTranslate('btn_more') }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="clients-holder">
        <div class="heading-holder">
            <span class="heading l">{{ langServ.returnTranslate('clients_heading') }}</span>
        </div>
        <div class="logo-holder">
            <div class="client-logo monolit"></div>
            <div class="client-logo alfa"></div>
            <div class="client-logo optix"></div>
            <div class="client-logo sigma"></div>
            <div class="client-logo moto"></div>
            <div class="client-logo nashensko"></div>
            <div class="client-logo tempex"></div>
            <div class="client-logo andy"></div>
            <div class="client-logo taf"></div>
            <div class="client-logo vlight"></div>
            <div class="client-logo anvers"></div>
            <div class="client-logo mania"></div>
            <div class="client-logo nord"></div>
            <div class="client-logo monolit2"></div>
        </div>
    </div>
</div>
<!-- Next Segment-------------------- -->
<div class="about-segment section" *ngIf="langServ.returnLanguageLoaded()">
    <div class="about-holder">
        <div class="heading-holder">
            <span class="heading l">{{ langServ.returnTranslate('about_us_heading') }}</span>
            <div class="number-bar">
                <span class="number active">03</span>
                <div class="line"></div>
                <span class="number">09</span>
            </div>
            <div class="btn right" routerLink="/about-us">
                <div class="next-ic"></div>
                <span class="btn-txt">{{ langServ.returnTranslate('more_about_us_btn') }}</span>
            </div>
        </div>
        <div class="info-card">
            <div class="info">
                <span class="heading s">{{ langServ.returnTranslate('about_company_heading') }}</span>
                <span class="main-txt">{{ langServ.returnTranslate('about_company_body') }}</span>
            </div>
            <div class="img-holder">
                <!-- <div class="img-pagination" style="width:80px;left:calc(50% - 40px)">
                    <div class="page-row" [ngClass]="{ 'selected':companyImage == 1 }" (click)="switchCompanyImage(1)">
                        <div class="page-dot"></div>
                    </div>
                    <div class="page-row" [ngClass]="{ 'selected':companyImage == 2 }" (click)="switchCompanyImage(2)">
                        <div class="page-dot"></div>
                    </div>
                </div> -->
                <div class="card-photo2 abo1-1-1-landing" [ngClass]="{ 'abo1-1-1-landing':companyImage == 1, 'abo1-2':companyImage == 2 }"></div>
            </div>
        </div>
        <div class="info-s-card">
            <div class="img-holder">
                <div class="card-photo2 abo3-2"></div>
            </div>
            <span class="heading xs">{{ langServ.returnTranslate('goals_heading') }}</span>
            <span class="main-txt">{{ langServ.returnTranslate('goals_body') }}</span>
        </div>
        <div class="info-s-card margin">
            <div class="img-holder">
                <div class="card-photo2 abo4-1-1"></div>
            </div>
            <span class="heading xs">{{ langServ.returnTranslate('technology_heading') }}</span>
            <span class="main-txt">{{ langServ.returnTranslate('technology_body') }}</span>
            <div class="btn left desktop-hide" routerLink="/about-us">
                <div class="next-ic"></div>
                <span class="btn-txt">{{ langServ.returnTranslate('more_about_us_1_btn') }}</span>
            </div>
        </div>
    </div>
</div>
<!-- <div class="scroll-btn" (click)="NextPage()" *ngIf="currentPage < 4 && langServ.returnLanguageLoaded()">
    <span class="scroll-btn-txt">{{ langServ.returnTranslate('next_btn') }}</span>
    <div class="scroll-ic"></div>
</div> -->

<ngx-json-ld [json]="schema"></ngx-json-ld>
<script>
    var mobilevideo = document.getElementById("video-bg");
    mobilevideo.setAttribute("playsinline", "");
    mobilevideo.setAttribute("muted", "");
</script>