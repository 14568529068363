import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../language.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router,NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.css']
})
export class NewProjectComponent implements OnInit {

  project:any;
  currentProject:any;
  currentPage:any = 0;
  currentImage = 0;
  image_count:any = [];
  
  constructor(public langServ:LanguageService, private http:HttpClient, private activeRoute: ActivatedRoute, private router: Router, private _location:Location) {
    this.activeRoute.params.subscribe(params => { 
      this.project = params.id; 
      this.GetProject();
    });
  }

  ngOnInit(): void {
  }

  scollPrevious() {
    if(this.currentImage > 0){
      this.currentImage -= 1;
      let photo_width = document.getElementsByClassName('gallery')[0].getBoundingClientRect().width;
      document.getElementsByClassName('gallery')[0].scrollTo(this.currentImage * ((photo_width / 100) * 40), 0);
    }
  }

  scrollNext() {
    if(this.currentImage < this.image_count.length - 1){
      this.currentImage += 1;
      let photo_width = document.getElementsByClassName('gallery')[0].getBoundingClientRect().width;
      document.getElementsByClassName('gallery')[0].scrollTo(this.currentImage * (((photo_width / 100) * 40) + 10), 0);
    }
  }

  goBack() { this._location.back(); }

  GetProject() {
    this.http.get("https://axion.solutions/api/service.php?action=get_project&id=" + this.project).subscribe(
    response => { 
      this.currentProject = response['project']; 
      this.currentProject.descriptions_bg = this.currentProject.description_bg.split(";");
      this.currentProject.descriptions_en = this.currentProject.description_en.split(";");
      this.currentProject.tags = this.currentProject.tag.split(";");
      for(let i = 0; i < this.currentProject.image_count; i++) {
        this.image_count.push("");
      }
    },
    error => {  });
  }

  returnProjectTitle() {
    if(this.langServ.returnLanguage() == "bg") { return this.currentProject.title_bg; }
    else { return this.currentProject.title_en; }
  }

  returnDescriptions() {
    if(this.langServ.returnLanguage() == "bg") { return this.currentProject.descriptions_bg; }
    else { return this.currentProject.descriptions_en; }
  }

  returnPages() {
    return document.getElementsByClassName('section');
  }

}
