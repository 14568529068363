<!-- <div class="scroll-btn" (click)="NextPage()">
    <span class="scroll-btn-txt">следваща</span>
    <div class="scroll-ic"></div>
</div> -->
<!-- Next Segment-------------------- -->
<div class="landing-segment fit section">
    <div class="photo-holder service"></div>
    <div class="main-holder">
        <div class="back-btn-holder">
            <div class="btn" (click)="goBack()">
                <div class="previous-ic"></div>
                <span class="btn-txt">{{ langServ.returnTranslate('back_btn') }}</span>
            </div>
        </div>
        <div class="filters-holder services">
            <div class="checkbox-bar">
                <div class="checkbox-holder" routerLink="/service/video">
                    <label class="container" [ngClass]="{ 'active': service == 'video' }">
                        <span>{{ langServ.returnTranslate('filter_video_label') }}</span>
                    </label>
                </div>

                <div class="checkbox-holder" routerLink="/service/software">
                    <label class="container" [ngClass]="{ 'active': service == 'software' }">
                        <span>{{ langServ.returnTranslate('filter_software_label') }}</span>
                    </label>
                </div>

                <div class="checkbox-holder" routerLink="/service/hardware">
                    <label class="container" [ngClass]="{ 'active': service == 'hardware' }">
                        <span>{{ langServ.returnTranslate('filter_hardware_label') }}</span>
                    </label>
                </div>

                <div class="checkbox-holder" routerLink="/service/design">
                    <label class="container" [ngClass]="{ 'active': service == 'design' }">
                        <span>{{ langServ.returnTranslate('filter_design_label') }}</span>
                    </label>
                </div>

                <div class="checkbox-holder" routerLink="/service/cloud">
                    <label class="container" [ngClass]="{ 'active': service == 'cloud' }">
                        <span>{{ langServ.returnTranslate('filter_cloud_label') }}</span>
                    </label>
                </div>

                <div class="checkbox-holder" routerLink="/service/network">
                    <label class="container" [ngClass]="{ 'active': service == 'network' }">
                        <span>{{ langServ.returnTranslate('filter_network_label') }}</span>
                    </label>
                </div>
            </div>
        </div>
        <div class="heading-holder">
            <span class="heading l">{{ langServ.returnTranslate('services_heading_' + service) }}</span>
        </div>
        <div class="info-card">
            <div class="info">
                <span class="heading s">{{ langServ.returnTranslate('services_heading_'+ service +'_1') }}</span>
                <p class="main-txt" [innerHTML]="langServ.returnTranslate('services_body_'+ service +'_1')"></p>

                <p class="main-txt" *ngIf="service == 'video'" [innerHTML]="langServ.returnTranslate('services_body_' + service + '_1_1')"></p>

            </div>
        </div>
        <div class="info-s-card">
            <div class="img-holder">
                <div class="card-photo2 {{service_short}}1"></div>
            </div>
        </div>
        <div class="info-s-card margin ">
            <div class="img-holder">
                <div class="card-photo2 {{service_short}}1-2"></div>
            </div>            
        </div>
    </div>
</div>
<!-- Next Segment-------------------- -->
<div class="main-segment section" *ngIf="service == 'video' || service == 'software' || service == 'hardware' || service == 'design'">
    <div class="main-holder">
        <div class="info-s-card left">
            <div class="img-holder">
                <div class="card-photo2 {{service_short}}2-3"></div>
            </div>
            <span class="heading xs">{{ langServ.returnTranslate('services_heading_'+ service +'_2') }}</span>
            <span class="main-txt" [innerHTML]="langServ.returnTranslate('services_body_'+ service +'_2')"></span>
        </div>
        <div class="info-s-card left margin">
            <div class="img-holder">
                <div class="card-photo2 {{service_short}}2-2"></div>
            </div>
            <span class="heading xs">{{ langServ.returnTranslate('services_heading_'+ service +'_3') }}</span>
            <span class="main-txt" [innerHTML]="langServ.returnTranslate('services_body_'+ service +'_3')"></span>
        </div> 
        <div class="info-card right">
            <div class="info">
                <!-- <span class="side-num l">02</span> -->
                <span class="heading s">{{ langServ.returnTranslate('services_heading_'+ service +'_4') }}</span>
                <span class="main-txt" [innerHTML]="langServ.returnTranslate('services_body_'+ service +'_4')"></span>
            </div>
            <div class="img-holder" (swipeleft)="swipeCurrentPhoto('right')" (swiperight)="swipeCurrentPhoto('left')">
                <!-- <div class="img-pagination" *ngIf="service != 'hardware'" [ngClass]="{ 'force-two':service == 'design' }">
                    <div class="page-row" [ngClass]="{ 'selected':currentPhoto == 1 }" (click)="switchCurrentPhoto(1)">
                        <div class="page-dot"></div>
                    </div>
                    <div class="page-row" [ngClass]="{ 'selected':currentPhoto == 2 }" (click)="switchCurrentPhoto(2)">
                        <div class="page-dot"></div>
                    </div>
                    <div class="page-row" *ngIf="service != 'design'" [ngClass]="{ 'selected':currentPhoto == 3 }" (click)="switchCurrentPhoto(3)">
                        <div class="page-dot"></div>
                    </div>    
                </div> -->
                <div class="card-photo2 {{service_short}}2-1-{{ currentPhoto }}"></div>
            </div>
        </div> 
    </div>      
</div>
<!-- Next Segment-------------------- -->
<div class="main-segment section" *ngIf="service == 'design'">
    <div class="main-holder">
        <div class="info-card">
            <div class="info">
                <span class="heading s">{{ langServ.returnTranslate('services_heading_'+ service +'_5') }}</span>
                <span class="main-txt" [innerHTML]="langServ.returnTranslate('services_body_'+ service +'_5')"></span>
            </div>
            <div class="img-holder" (swipeleft)="swipeCurrentPhotoNext('right')" (swiperight)="swipeCurrentPhotoNext('left')">
                <!-- <div class="img-pagination" style="width:80px;left:calc(50% - 40px);">
                    <div class="page-row" [ngClass]="{ 'selected':currentPhotoNext == 1 }" (click)="switchCurrentPhotoNext(1)">
                        <div class="page-dot"></div>
                    </div>
                    <div class="page-row" [ngClass]="{ 'selected':currentPhotoNext == 2 }" (click)="switchCurrentPhotoNext(2)">
                        <div class="page-dot"></div>
                    </div>    
                </div> -->
                <div class="card-photo2 des3-1-{{currentPhotoNext}}"></div>
            </div>
        </div> 
        <div class="info-s-card">
            <div class="img-holder">
                <div class="card-photo2 des3-2"></div>
            </div>
            <span class="heading xs">{{ langServ.returnTranslate('services_heading_'+ service +'_6') }}</span>
            <span class="main-txt" [innerHTML]="langServ.returnTranslate('services_body_'+ service +'_6')"></span>
        </div>
        <div class="info-s-card margin">
            <div class="img-holder">
                <div class="card-photo2 des3-3"></div>
            </div>
            <span class="heading xs">{{ langServ.returnTranslate('services_heading_'+ service +'_7') }}</span>
            <span class="main-txt" [innerHTML]="langServ.returnTranslate('services_body_'+ service +'_7')"></span>
        </div> 
    </div>      
</div>

<!-- <div class="pages-bar">
    <div class="page-row sidepage" *ngFor="let page of returnPages(); let i = index" (click)="scrollToPage(i)">
        <div class="page-dot"></div>
        <span class="page-txt"></span>
    </div>
</div> -->
<script type="application/ld+json">
    {
        "@context": "http://schema.org",
        "@type": "ProfessionalService",
        "name": "Видео Аналитика",
        "description": "Създаваме аналитични модули за идентификация на лица, засичане на хора и автомобили, разчитане на регистрационни номера, детекция на огън и пушек, снемане на демографски данни, засичане на оръжия.",
        "address": "ул.Георги Данчов 18, 4023 Тракия, Пловдив, България",
        "telephone": "+35970011267",
        "image": "https://axion.solutions/axion-hero.jpg"
    }
</script>