import { Injectable, Inject } from '@angular/core';

// declare var window;
@Injectable({
  providedIn: 'root'
})
export class FacebookServiceService {

  constructor() { this.load(); }
  load() {
    // (function (f: any, b, e, v, n, t, s) {
    //     if (f.fbq) return; n = f.fbq = function () {
    //         n.callMethod ?
    //             n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    //     }; if (!f._fbq) f._fbq = n;
    //     n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async = !0;
    //     t.src = v; s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s)
    // })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    // (window as any).fbq.disablePushState = true; //not recommended, but can be done
    // (window as any).fbq('init', '2450616621703318');
    // (window as any).fbq('track', 'PageView');
    // console.log("Done");
  }
}
