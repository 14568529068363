<div class="new-segment">
    <div class="back-btn-holder">
        <div class="btn" (click)="goBack()">
            <div class="previous-ic"></div>
            <span class="btn-txt" id="back_btn">Назад</span>
        </div>
    </div>
    <div class="background-holder pro{{project}}"></div>
    <div class="new-txt-holder">
        <div class="heading-holder">
            <span class="heading m">{{ returnProjectTitle() }}</span>
        </div>
        <div class="scroll-content">
            <p class="main-txt">
                <!-- <span class="heading xxs">Заглавие на параграф</span> -->
                <span *ngFor="let description of returnDescriptions();">{{ description }}</span>
            </p>
            <!-- <p class="main-txt">
                <span class="heading xxs">Заглавие на параграф</span>
                GPS навигацията ни осъществява проследяване на вашите камиони,
                автомобили под наем или лизингови превозни средства.
                С безплатното отчитане на таксите в Тол БГ ще бъдете спокойни,
                че винаги сте изрядни. GPS навигацията ни осъществява проследяване на вашите камиони,
                автомобили под наем или лизингови превозни средства.
                С безплатното отчитане на таксите в Тол БГ ще бъдете спокойни,
                че винаги сте изрядни. 
            </p> -->
        </div>
        <div class="details-holder">
            <div class="tag-holder">
                <span class="heading xxs">Категории</span>
                <div class="tag {{tag}}" *ngFor="let tag of currentProject.tags">
                    <span *ngIf="tag == 'design'">{{ langServ.returnTranslate('tag_design') }}</span>
                    <span *ngIf="tag == 'software'">{{ langServ.returnTranslate('tag_software') }}</span>
                    <span *ngIf="tag == 'hardware'">{{ langServ.returnTranslate('tag_hardware') }}</span>
                    <span *ngIf="tag == 'network'">{{ langServ.returnTranslate('tag_network') }}</span>
                    <span *ngIf="tag == 'video'">{{ langServ.returnTranslate('tag_video') }}</span>
                    <span *ngIf="tag == 'cloud'">{{ langServ.returnTranslate('tag_cloud') }}</span>
                </div>   
                <!-- <div class="tag end-to-end">End-to-end
                    <span class="star">&#9733;</span>
                </div> -->
            </div>
            <div class="client-holder">
                <span class="heading xxs">Клиент</span>
                <div class="client {{ currentProject.client }}"></div>
            </div>
        </div>
    </div>
    <div class="gallery-holder">
        <div class="gallery">
            <div class="gallery-card" *ngFor="let image of image_count;let i = index">
                <div class="card-photo2 pro{{project}}-{{ i + 1 }}"></div>
            </div>
        </div>
        <div class="arrowbar">
            <div class="arrowbar-back-btn" (click)="scollPrevious()">
                <div class="arrowbar-ic back"></div>
                <span class="btn-txt">предишни</span>
            </div>
            <div class="arrowbar-next-btn" (click)="scrollNext()">
                <div class="arrowbar-ic next"></div>
                <span class="btn-txt">следващи</span>
            </div>
        </div>
    </div>
</div>
<div class="social-bar">
    <div class="social-btn link-ic"></div>
    <div class="social-btn fb-ic"></div>
    <div class="social-btn insta-ic"></div>
    <div class="social-btn youtube-ic"></div>
</div>